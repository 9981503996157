<template>
  <v-container>
    <v-app-bar app color="white" flat>
      <h2 class="custom-font ml-12 font-weight-bold">Chuuya Johänntgen</h2>

      <v-spacer></v-spacer>
      <div>
        <v-tabs>
          <v-tab
            class="custom-font font-weight-bold"
            @click="handleClick('nothello')"
            >Work</v-tab
          >
          <v-tab
            class="custom-font font-weight-bold"
            @click="handleClick('hello')"
            >Contact</v-tab
          >
        </v-tabs>
      </div>
    </v-app-bar>
    <v-row class="" ref="works">
      <v-col cols="12" lg="4" xl="4">
        <p class="custom-font font-weight-bold">
          I’m a designer interested in making beautiful UI’s.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="6" xl="4" class="mb-16 btn-behavior">
        <v-img
          src="@/assets/images/ui-examples-thumb.png"
          @click="$router.push({ name: 'ui' })"
        ></v-img>
        <h2 class="custom-font font-weight-bold mt-8">APP UI Examples</h2>
        <p class="custom-font mt-3">
          A dive through my work examples for different App UI’s.
        </p>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="4" class="mb-16 btn-behavior">
        <v-img
          src="@/assets/images/ghostly-thumb.png"
          @click="$router.push({ name: 'orbs' })"
        ></v-img>
        <h2 class="custom-font font-weight-bold mt-8">Ghostly Orbs</h2>
        <p class="custom-font mt-3">
          A game I made in university using my art skills as well as my pure
          imagination.
        </p>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="4" class="mb-16 btn-behavior">
        <v-img
          src="@/assets/images/art-thumb.png"
          @click="$router.push({ name: 'art' })"
        ></v-img>
        <h2 class="custom-font font-weight-bold mt-8">Artwork</h2>
        <p class="custom-font mt-3">
          I have been drawing over 20 years. Take a look at selected pieces of
          my most beloved hobby.
        </p>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="4" class="mb-16 btn-behavior">
        <v-img
          src="@/assets/images/landing-thumb.png"
          @click="$router.push({ name: 'landing' })"
        ></v-img>
        <h2 class="custom-font font-weight-bold mt-8">Landing Pages</h2>
        <p class="custom-font mt-3">A varietion of Landing Page examples.</p>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="4" class="mb-16 btn-behavior">
        <v-img
          src="@/assets/images/pets-thumb.png"
          @click="$router.push({ name: 'pets' })"
        ></v-img>
        <h2 class="custom-font font-weight-bold mt-8">Pet’s Vacation</h2>
        <p class="custom-font mt-3">
          A mobile app I came up with in university.
        </p>
      </v-col>
    </v-row>
    <v-row ref="targetRef">
      <v-col class="my-16">
        <h2 class="custom-font font-weight-bold mt-16">Get in touch</h2>
        <v-btn depressed dark color="primary" class="mb-16 mt-8"
          >chuuyajohaenntgen@web.de</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),
  methods: {
    handleClick(route) {
      if (route === "hello") {
        this.$refs.targetRef.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$refs.works.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
<style lang="scss">
.btn-behavior:hover {
  cursor: pointer;
}
</style>
