<template>
  <v-container>
    <v-app-bar app color="white" flat>
      <h2 class="custom-font ml-12 font-weight-bold">Landing Pages</h2>

      <v-spacer></v-spacer>
      <div>
        <v-tabs>
          <v-tab
            class="custom-font font-weight-bold"
            @click="$router.push({ name: 'home' })"
            >Work</v-tab
          >
          <v-tab
            class="custom-font font-weight-bold"
            @click="handleClick('hello')"
            >Contact</v-tab
          >
        </v-tabs>
      </div>
    </v-app-bar>
    <v-row class="">
      <v-col cols="12" lg="4" xl="4">
        <p class="custom-font font-weight-bold">
          A Page dedicated to my favourite Tea: Matcha
        </p>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="7" lg="7" xl="5">
        <v-img src="@/assets/images/landing/matcha.png"></v-img>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="3">
        <v-img src="@/assets/images/landing/matcha-2.png"></v-img>
      </v-col>
    </v-row>

    <v-row class="mt-16">
      <v-col cols="12" lg="4" xl="4">
        <p class="custom-font font-weight-bold">
          A page for a not existing company that would help me travel easier.
        </p>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="7" lg="7" xl="5">
        <v-img src="@/assets/images/landing/limitless-1.png"></v-img>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="3">
        <v-img src="@/assets/images/landing/limitless-2.png"></v-img>
        <v-img
          src="@/assets/images/landing/limitless-3.png"
          class="mt-10"
        ></v-img>
      </v-col>
    </v-row>
    <v-row ref="targetRef">
      <v-col class="my-16">
        <h2 class="custom-font font-weight-bold mt-16">Get in touch</h2>
        <v-btn depressed color="primary" class="mb-16 mt-8"
          >chuuyajohaenntgen@web.de</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UIexamples",

  data: () => ({}),
  methods: {
    handleClick(route) {
      if (route === "hello") {
        this.$refs.targetRef.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$refs.works.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
