<template>
  <v-container>
    <v-app-bar app color="white" flat>
      <h2 class="custom-font ml-12 font-weight-bold">Pets Vacation</h2>

      <v-spacer></v-spacer>
      <div>
        <v-tabs>
          <v-tab
            class="custom-font font-weight-bold"
            @click="$router.push({ name: 'home' })"
            >Work</v-tab
          >
          <v-tab
            class="custom-font font-weight-bold"
            @click="handleClick('hello')"
            >Contact</v-tab
          >
        </v-tabs>
      </div>
    </v-app-bar>
    <v-row class="mb-16">
      <v-col cols="12" lg="4" xl="4">
        <p class="custom-font font-weight-bold">
          App concept for a petsitting service.
        </p>
      </v-col>
    </v-row>

    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around" class="my-16">
      <v-col cols="12">
        <v-img src="@/assets/images/pets/app-header.png"></v-img>
      </v-col>
    </v-row>

    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-between" align="center">
      <v-col
        cols="12"
        lg="4"
        xl="4"
        :order="this.$vuetify.breakpoint.smAndDown ? '2' : '1'"
      >
        <v-img src="@/assets/images/pets/app-illu.png"></v-img>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        xl="4"
        :order="this.$vuetify.breakpoint.smAndDown ? '1' : '2'"
      >
        <h3 class="custom-font font-weight-bold mb-4">
          Grundidee der Applikation
        </h3>
        <p class="custom-font">
          Fiktive App für Hundebesitzer, welche Betreuung für ihr Haustier wegen
          Urlaub o.Ä. suchen und für Nutzer, welche Tierbetreuung anbieten.<br />
          <br />
          <span class="text-primary">
            Persona einer fiktiven Person als Hilfe zur Visualisierung der
            anzusprechenden Zielgruppe.<br />
            <br />
            Entsprechende Person inklusive Haustier dienen als Fotovorlage für
            das Design der App.
          </span>
        </p>
      </v-col>
    </v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-between">
      <v-col cols="12" lg="4" xl="4">
        <h3 class="custom-font font-weight-bold mb-4">Interview</h3>
        <p class="custom-font">
          Vier Personen, welche absolut unterschiedlich sind, aber alle das
          gleiche Problem teilen.<br />
          <br />
          Um die App zu gestalten und zu entwickeln habe ich im Vorfeld Personen
          interviewed um mir ein persönliches Bild des Problems zu machen und um
          herauszufinden, welche Wünsche die Zielgruppe hat.
        </p>
      </v-col>
      <v-col cols="12" lg="4" xl="4">
        <h3 class="custom-font font-weight-bold mb-4">Personeninformationen</h3>
        <p class="custom-font">
          Die vier Personen bestehen aus folgenden 
				Persönlichkeiten:<br>
				<br>
				<ol class="ml-4 text-primary">
					<li>Vollzeit Arbeitskraft, Hund muss jeden Tag 9 Stunden alleine sein.</li>
					<li>Teilzeitkraft, Hund muss nur alle 3 Tage 9 Stunden alleine sein.</li>
					<li>Vollzeit im Home Office, Hund kann nicht alleine bleiben, wenn der Besitzer mal nicht da ist, da der Hund es nicht gewohnt ist.</li>
					<li>Studentin, welche in ihrer Freizeit gerne Tiere betreuen würde.</li>
				</ol>
				<br>
				Gemeinsamkeit der ersten drei Personen: Urlaub ohne Hund gestaltet sich als schwierig, wegen fehlender 
				Unterkunft.
        </p>
      </v-col>
    </v-row>

    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-between">
      <v-col cols="12" lg="4" xl="4">
        <h3 class="custom-font font-weight-bold mb-4">Probleme</h3>
        <p class="custom-font">
          <ul class="ml-4">
						<li>zu viele Suchportale im World Wide Web</li>
						<li>seriös von unseriös kaum zu unterscheiden</li>
						<li>bei Geringverdienern Geldnot, da seriöse kostenlose Personen schwer zu finden sind</li>
						<li>Pfleger in der Nähe der Wohnung zu suchen gestaltet sich schwierig</li>
						<li>Spontanunterkunft wegen Notfall ohne großen Aufwand finden ist schwer</li>
					</ul>
        </p>
      </v-col>
      <v-col cols="12" lg="4" xl="4">
        <h3 class="custom-font font-weight-bold mb-4">Lösungen</h3>
        <p class="custom-font">
        <ul class="ml-4">
						<li>eine einzige App, welche Gebote sowie Suchangebote vereint</li>
						<li>Bewertungssystem welches auf Fakebot-Bewertungen kontrolliert wird </li>
						<li>normale Nutzung ist kostenlos, sodass Privatpersonen ihre Hilfe ohne Entgeld anbieten können.</li>
						<li>Map-System welches anzeigt, wo genau eine Pflegestelle zur verfügung steht, verfügt zudem über einen einstellbaren Radius (z.B zwei km in Wohnungsnähe)</li>
						<li>Erreichbarkeit der Pflegestellen von Nutzern einstellbar, sowie Hinterlegung von Telefonnummern freiwillig möglich. 
								Die Applikation benachrichtet zudem sobald man eine Anfrage/Nachricht bekommt</li>
					</ul>
        </p>
      </v-col>
    </v-row>

    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around" class="my-16">
      <v-col cols="12" lg="10" xl="8">
        <h3 class="custom-font font-weight-bold mb-4">Persona</h3>
        <v-img src="@/assets/images/pets/persona.png"></v-img>
      </v-col>
    </v-row>

    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around" class="my-16">
      <v-col cols="12" lg="10" xl="8">
        <h3 class="custom-font font-weight-bold mb-4">Wireframes</h3>
        <v-img src="@/assets/images/pets/wireframes.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around" class="my-16">
      <v-col cols="12" lg="10" xl="8">
        <h3 class="custom-font font-weight-bold mb-4">Prototype</h3>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app1.png"></v-img>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app2.png"></v-img>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app3.png"></v-img>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app4.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app5.png"></v-img>
      </v-col>
      <v-col cols="12" lg="6" xl="5">
        <h3 class="custom-font font-weight-bold mb-4">Main menu</h3>
        <p class="custom-font">
        Wichtige Funktionen sind im Main sowie im
				„Hamburger-Menu“ untergebracht.<br>
				<br>
				Die drei wichtigsten Funktionen sind:<br>
				<br>
        <ul>
				<li>Suche/Search</li>
				<li>Nachrichten/Messages</li>
				<li>Angebot erstellen/Create an offer</li>
        </ul>
        <br>
				<br>
				Genau diese Pfade sind im Prototyp 
				durchspielbar.
        </p>
      </v-col>
    </v-row>

    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app6.png"></v-img>
      </v-col>
      <v-col cols="12" lg="6" xl="5">
        <h3 class="custom-font font-weight-bold mb-4">Profilfunktion</h3>
        <p class="custom-font">
        Wichtig für Nutzer dieser Applikation ist die Bewertungsfunktion. Sie soll Sicherheit geben, 
				da das Anvertrauen des Haustieres an jemand Fremden mit viel Überwindung verbunden ist.<br>
				<br>
				Des Weiteren sollte direkt einsehbar sein, ob die Person nur an bestimmten Tagen Zeit hat und ob sie selbst Haustiere 
				besitzt. Nicht jeder Hund versteht sich mit anderen Tieren oder gar Artgenossen.<br>
				<br>
				Ob der Service kostenlos oder gegen ein Entgelt zur Verfügung gestellt wird ist auch ein wichtiges Kriterium, da nicht 
				jeder es sich finanziell leisten kann.
        </p>
      </v-col>
    </v-row>

    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app7.png"></v-img>
      </v-col>
      <v-col cols="12" lg="6" xl="5">
        <h3 class="custom-font font-weight-bold mb-4">Suchfunktion</h3>
        <p class="custom-font">
        Wochentage, Pflegeort und das 
				Vorhandensein von anderen Tieren kann schon in der Suchfunktion eingestellt 
				werden. <br>
				<br>
				Zuerst war die letztere Funktion nicht in der App. Nachdem der Click Dummy von den vier Personen aus dem 
				Interviewprozess getestet wurde, gaben zwei davon die Rückmeldung diese 
				Auswahl in der Suche vermisst zu haben.
				Daraufhin wurde es angepasst.
        </p>
      </v-col>
    </v-row>

    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app8.png"></v-img>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app9.png"></v-img>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img style="border:1px solid #d9d9d9" src="@/assets/images/pets/app10.png"></v-img>
      </v-col>
    </v-row>

    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row ref="targetRef">
      <v-col class="my-16">
        <h2 class="custom-font font-weight-bold mt-16">Get in touch</h2>
        <v-btn depressed color="primary" class="mb-16 mt-8"
          >chuuyajohaenntgen@web.de</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UIexamples",

  data: () => ({}),
  methods: {
    handleClick(route) {
      if (route === "hello") {
        this.$refs.targetRef.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$refs.works.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
