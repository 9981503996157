import Vue from "vue";
import VueRouter from "vue-router";
import HelloWorld from "@/components/HelloWorld.vue";
import UIexamples from "@/components/UIexamples.vue";
import LandingPages from "@/components/LandingPages.vue";
import GhostlyOrbs from "@/components/GhostlyOrbs.vue";
import ArtWorks from "@/components/ArtWorks.vue";
import PetsVacation from "@/components/PetsVacation.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HelloWorld,
  },
  {
    path: "/ui-examples",
    name: "ui",
    component: UIexamples,
  },
  {
    path: "/landing-pages",
    name: "landing",
    component: LandingPages,
  },
  {
    path: "/ghostly-orbs",
    name: "orbs",
    component: GhostlyOrbs,
  },
  {
    path: "/artworks",
    name: "art",
    component: ArtWorks,
  },
  {
    path: "/pets-vacation",
    name: "pets",
    component: PetsVacation,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
