<template>
  <v-container>
    <v-app-bar app color="white" flat>
      <h2 class="custom-font ml-12 font-weight-bold">Ghostly Orbs</h2>

      <v-spacer></v-spacer>
      <div>
        <v-tabs>
          <v-tab
            class="custom-font font-weight-bold"
            @click="$router.push({ name: 'home' })"
            >Work</v-tab
          >
          <v-tab
            class="custom-font font-weight-bold"
            @click="handleClick('hello')"
            >Contact</v-tab
          >
        </v-tabs>
      </div>
    </v-app-bar>
    <v-row class="mb-16">
      <v-col cols="12" lg="4" xl="4">
        <p class="custom-font font-weight-bold">
          A little game completely programmed and drawn by yours truly.
        </p>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" lg="4" xl="4">
        <h3 class="custom-font font-weight-bold mb-4">
          It all started with my love for Jump n Run games
        </h3>
        <p class="custom-font">
          and that I had dreamed of bringing something I created to life.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="7" lg="5" xl="4">
        <v-img src="@/assets/images/orbs/orbs1.png" class="mb-4"></v-img>
        <p class="custom-font font-weight-bold">...Not as drawing.</p>
      </v-col>
    </v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="end">
      <v-col cols="12" md="7" lg="5" xl="4">
        <p class="custom-font font-weight-bold">
          ... Neither as a crafted sclupture.
        </p>
        <v-img src="@/assets/images/orbs/orbs2.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="center" class="my-16">
      <v-col cols="12" md="7" lg="5" xl="4" class="text-center">
        <p class="custom-font font-weight-bold">But as a moving game.</p>
        <v-img src="@/assets/images/orbs/orbs-3.gif"></v-img>
      </v-col>
    </v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around" class="my-16">
      <v-col cols="12" md="6" lg="5" xl="4">
        <h3 class="custom-font font-weight-bold mb-12">
          A little recording of gameplay
        </h3>
        <p class="custom-font">
          I programmed it completely in Construct 2. All images are drawn in
          Paint Tool Sai. The build process as well as the end product were all
          part of the grading. Most time was spent in the images. Less time was
          spend on programming, since I made some artworks for later appearing
          Scenes for the game, which in the end, never made it into the game
          itself, as it is only meant to be playable for one level. maybe one
          day it becomes more?
        </p>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="5">
        <video controls width="100%" class="mt-12">
          <source src="@/assets/images/orbs/demo-video.mp4" type="video/mp4" />
        </video>
      </v-col>
    </v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="space-around" class="my-16">
      <v-col cols="12">
        <h3 class="custom-font font-weight-bold mb-12">
          Have some artworks I did for my work with the Game idea
        </h3>
      </v-col>
      <v-col cols="12">
        <v-img src="@/assets/images/orbs/orbs5.png"></v-img>
      </v-col>
      <v-col cols="12" md="6" lg="5" xl="5">
        <v-img src="@/assets/images/orbs/orbs6.png"></v-img>
      </v-col>
      <v-col cols="12" md="6" lg="5" xl="5">
        <v-img src="@/assets/images/orbs/orbs7.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row class="my-16"><div style="height: 1px"></div></v-row>
    <v-row justify="center" class="my-16">
      <v-col cols="12">
        <h3 class="custom-font font-weight-bold mb-12">
          Here you see Part of the Level with visible “Pillars to stand on”
          which are not visible in the gameplay itself.
        </h3>
      </v-col>
      <v-col cols="12">
        <v-img src="@/assets/images/orbs/orbs8.png"></v-img>
      </v-col>
    </v-row>

    <v-row ref="targetRef">
      <v-col class="my-16">
        <h2 class="custom-font font-weight-bold mt-16">Get in touch</h2>
        <v-btn depressed color="primary" class="mb-16 mt-8"
          >chuuyajohaenntgen@web.de</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UIexamples",

  data: () => ({}),
  methods: {
    handleClick(route) {
      if (route === "hello") {
        this.$refs.targetRef.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$refs.works.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
