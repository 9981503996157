<template>
  <v-container>
    <v-app-bar app color="white" flat>
      <h2 class="custom-font ml-12 font-weight-bold">APP UI Examples</h2>

      <v-spacer></v-spacer>
      <div>
        <v-tabs>
          <v-tab
            class="custom-font font-weight-bold"
            @click="$router.push({ name: 'home' })"
            >Work</v-tab
          >
          <v-tab
            class="custom-font font-weight-bold"
            @click="handleClick('hello')"
            >Contact</v-tab
          >
        </v-tabs>
      </div>
    </v-app-bar>
    <v-row class="">
      <v-col cols="12" lg="4" xl="4">
        <p class="custom-font font-weight-bold">Design for a vacation app.</p>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img src="@/assets/images/ui/vacation-1.png"></v-img>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-img src="@/assets/images/ui/vacation-2.png"></v-img>
      </v-col>
    </v-row>
    <v-row ref="targetRef">
      <v-col class="my-16">
        <h2 class="custom-font font-weight-bold mt-16">Get in touch</h2>
        <v-btn depressed color="primary" class="mb-16 mt-8"
          >chuuyajohaenntgen@web.de</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UIexamples",

  data: () => ({}),
  methods: {
    handleClick(route) {
      if (route === "hello") {
        this.$refs.targetRef.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$refs.works.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
