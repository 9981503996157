<template>
  <v-app>
    <!--<v-app-bar app color="white" flat>
      <h2 class="custom-font ml-12 font-weight-bold">Chuuya Johänntgen</h2>

      <v-spacer></v-spacer>
      <div>
      <v-tabs>
        <v-tab class="custom-font font-weight-bold">Work</v-tab>
        <v-tab class="custom-font font-weight-bold">Contact</v-tab>
      </v-tabs>
      </div>
    </v-app-bar>-->

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
.custom-font {
  font-family: poppins !important;
}
</style>
